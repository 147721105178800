var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._t("close"),_c('v-spacer'),_vm._v(" 導入取材報告 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommit}},[_vm._v("登録")])],2),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('div',[(_vm.demo)?[_c('DemoResult',{attrs:{"demo":_vm.demo}}),_c('v-divider')]:_vm._e(),(_vm.hpCoverage)?[_c('HPCoverageResult',{attrs:{"hpCoverage":_vm.hpCoverage}}),_c('v-divider')]:_vm._e(),_c('v-expansion-panels',{model:{value:(_vm.expansions),callback:function ($$v) {_vm.expansions=$$v},expression:"expansions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.date)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.date) ? '*導入取材完了日を入力してください' : '*導入取材完了日'))]),_c('span',{staticClass:"ml-4 success--text",domProps:{"textContent":_vm._s(_vm.$options.filters.date(_vm.creator.date))}})],1)]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('XFieldSet',{attrs:{"label":"導入取材完了日"}},[_c('validation-provider',{attrs:{"name":"導入取材完了日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"導入取材完了日","hideTime":"","error-messages":errors},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("date")(_vm.date))+" ")])]}}],null,true),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.date = null;
                          _vm.creator.date = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.date = _vm.date;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":_vm.creator.setuper.id != null && _vm.creator.coverager.id != null && _vm.creator.submiter.id != null}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.setuper) || _vm.isEmptyValue(_vm.creator.submiter) ? '導入・取材・構成担当・構成提出予定日情報を登録してください' : '導入・取材・構成担当・構成提出予定日'))]),(_vm.creator.setuper.id != null && _vm.creator.coverager.id != null && _vm.creator.submiter.id != null)?_c('span',{staticClass:"ml-4 success--text"},[_vm._v(" 導入:"),(_vm.creator.setuper != null)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.creator.setuper.name))]):_vm._e(),_vm._v(" 取材:"),(_vm.creator.coverager != null)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.creator.coverager.name))]):_vm._e(),_vm._v(" 構成:"),(_vm.creator.submiter != null)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.creator.submiter.name))]):_vm._e()]):_vm._e(),(_vm.creator.submitscheduleDate != null)?_c('span',{staticClass:"ml-4 success--text"},[_vm._v("構成提出予定日:"+_vm._s(_vm._f("date")(_vm.creator.submitscheduleDate)))]):_vm._e()],1)]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-caption"},[_vm._v("この「拠点」はデモ結果から参照しています。変更しても結果に反映されません。")]),_c('validation-provider',{attrs:{"name":"支店","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.shitens,"label":"支店","item-text":"name","item-value":"id","error-messages":errors,"return-object":"","dense":""},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"導入担当","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.staffs,"label":"導入担当","item-text":"name","item-value":"id","error-messages":errors,"return-object":"","outlined":"","dense":""},model:{value:(_vm.setuper),callback:function ($$v) {_vm.setuper=$$v},expression:"setuper"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"取材担当","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.staffs,"label":"取材担当","item-text":"name","item-value":"id","error-messages":errors,"return-object":"","outlined":"","dense":""},model:{value:(_vm.coverager),callback:function ($$v) {_vm.coverager=$$v},expression:"coverager"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"構成担当","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.staffs,"label":"構成担当","item-text":"name","item-value":"id","error-messages":errors,"return-object":"","outlined":"","dense":""},model:{value:(_vm.submiter),callback:function ($$v) {_vm.submiter=$$v},expression:"submiter"}})]}}],null,true)})],1)],1),_c('XFieldSet',{attrs:{"label":"構成提出予定日"}},[_c('validation-provider',{attrs:{"name":"構成提出予定日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"構成提出予定日","hideTime":"","error-messages":errors},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("date")(_vm.submitscheduleDate))+" ")])]}}],null,true),model:{value:(_vm.submitscheduleDate),callback:function ($$v) {_vm.submitscheduleDate=$$v},expression:"submitscheduleDate"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.setuper = null;
                          _vm.coverager = null;
                          _vm.submiter = null;
                          _vm.submitscheduleDate = null;
                          _vm.creator.setuper = null;
                          _vm.creator.coverager = null;
                          _vm.creator.submiter = null;
                          _vm.creator.submitscheduleDate = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.setuper = _vm.setuper;
                          _vm.creator.coverager = _vm.coverager;
                          _vm.creator.submiter = _vm.submiter;
                          _vm.creator.submitscheduleDate = _vm.submitscheduleDate;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.meta.coverageCollectType)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.meta.coverageCollectType) ? '*リース用紙回収区分を登録してください' : '*リース用紙回収区分'))]),_c('span',{staticClass:"ml-4 success--text",domProps:{"textContent":_vm._s(_vm.$options.filters.coverageCollectTypes(_vm.creator.meta.coverageCollectType))}})],1)]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"リース用紙回収区分","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":[
                            { text: _vm.$options.filters.coverageCollectTypes(1), value: 1 },
                            { text: _vm.$options.filters.coverageCollectTypes(1 << 1), value: 1 << 1 },
                            { text: _vm.$options.filters.coverageCollectTypes(1 << 2), value: 1 << 2 },
                            { text: _vm.$options.filters.coverageCollectTypes(1 << 9), value: 1 << 9 } ],"label":"*リース用紙回収区分","rules":[function (v) { return !!v || 'リース用紙回収区分は必須です'; }],"error-messages":errors,"required":"","outlined":"","dense":""},model:{value:(_vm.coverageCollectType),callback:function ($$v) {_vm.coverageCollectType=$$v},expression:"coverageCollectType"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.coverageCollectType = null;
                          _vm.creator.meta.coverageCollectType = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid || _vm.coverageCollectType == 0},on:{"click":function($event){_vm.creator.meta.coverageCollectType = _vm.coverageCollectType;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1),_c('p',{staticClass:"text-caption"},[_vm._v("※未回収・回収OK(提出待ち)の場合は理由を備考に記入してください。")])]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.moveTime) && !_vm.isEmptyValue(_vm.creator.coverageTime)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.moveTime) || _vm.isEmptyValue(_vm.creator.coverageTime) ? '移動・取材時間を登録してください' : '移動・取材時間'))]),(_vm.creator.moveTime != null)?_c('span',{staticClass:"ml-4 success--text"},[_vm._v(" 移動:"),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.creator.moveTime)+"時間")]),_vm._v(" 取材:"),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.creator.coverageTime)+"時間")])]):_vm._e()],1)]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"移動時間","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"移動時間","clearable":"","dense":"","suffix":"時間","counter":"","maxlength":2,"error-messages":errors},model:{value:(_vm.moveTime),callback:function ($$v) {_vm.moveTime=_vm._n($$v)},expression:"moveTime"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"取材時間","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"取材時間","clearable":"","dense":"","suffix":"時間","counter":"","maxlength":2,"error-messages":errors},model:{value:(_vm.coverageTime),callback:function ($$v) {_vm.coverageTime=_vm._n($$v)},expression:"coverageTime"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.moveTime = null;
                          _vm.coverageTime = null;
                          _vm.creator.moveTime = null;
                          _vm.creator.coverageTime = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.moveTime = _vm.moveTime;
                          _vm.creator.coverageTime = _vm.coverageTime;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.cost)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.cost) ? '取材経費を登録してください' : '取材経費'))]),(_vm.creator.cost != null)?_c('span',{staticClass:"ml-4 success--text"},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("priceJP")(_vm.creator.cost)))])]):_vm._e()],1)]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"取材経費","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"取材経費","clearable":"","dense":"","suffix":"円","counter":"","maxlength":100,"error-messages":errors},model:{value:(_vm.cost),callback:function ($$v) {_vm.cost=_vm._n($$v)},expression:"cost"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.cost = null;
                          _vm.creator.cost = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.cost = _vm.cost;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_vm._v(" その他 "),_c('span',{staticClass:"success--text"},[_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.creator.meta.addCoverage ? '追加取材' : ''))]),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm._f("domain")(_vm.creator.meta.domain)))]),(_vm.creator.meta.domainName != null)?_c('span',{staticClass:"ml-4"},[_vm._v("["+_vm._s(_vm.creator.meta.domainName)+_vm._s(_vm.creator.meta.domainSuffix)+"]")]):_vm._e()])])]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-start"},[_c('v-checkbox',{attrs:{"label":"追加取材"},model:{value:(_vm.meta.addCoverage),callback:function ($$v) {_vm.$set(_vm.meta, "addCoverage", $$v)},expression:"meta.addCoverage"}})],1),_c('XFieldSet',{attrs:{"label":"ドメイン"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{attrs:{"name":"ドメイン","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"column":"","dense":""},model:{value:(_vm.meta.domain),callback:function ($$v) {_vm.$set(_vm.meta, "domain", _vm._n($$v))},expression:"meta.domain"}},_vm._l(([
                                    { value: 1, text: '既存ドメイン使用' },
                                    { value: 1 << 1, text: 'ドメイン移管' },
                                    { value: 1 << 2, text: '取得代行' } ]),function(item,index){return _c('v-radio',{key:index,attrs:{"label":item.text,"value":item.value}})}),1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"ドメイン名","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ドメイン名","counter":"","maxlength":100,"error-messages":errors,"clearable":"","dense":""},model:{value:(_vm.meta.domainName),callback:function ($$v) {_vm.$set(_vm.meta, "domainName", $$v)},expression:"meta.domainName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"domain","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":['.com', '.net', '.jp'],"label":"domain","counter":"","maxlength":100,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.meta.domainSuffix),callback:function ($$v) {_vm.$set(_vm.meta, "domainSuffix", $$v)},expression:"meta.domainSuffix"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.meta.addCoverage = false;
                          _vm.meta.domain = null;
                          _vm.meta.domainName = null;
                          _vm.meta.domainSuffix = null;

                          _vm.creator.meta.addCoverage = false;
                          _vm.creator.meta.domain = null;
                          _vm.creator.meta.domainName = null;
                          _vm.creator.meta.domainSuffix = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.meta.addCoverage = _vm.meta.addCoverage;
                          _vm.creator.meta.domain = _vm.meta.domain;
                          _vm.creator.meta.domainName = _vm.meta.domainName;
                          _vm.creator.meta.domainSuffix = _vm.meta.domainSuffix;

                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.memo)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.memo) ? '備考を登録してください' : '備考'))]),_c('span',{staticClass:"d-inline-block text-truncate ml-2",staticStyle:{"max-width":"120px"}},[_vm._v(_vm._s(_vm.creator.memo))])],1),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","rows":"3","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.memo = null;
                          _vm.creator.memo = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.memo = _vm.memo;
                          _vm.expansions = null;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1)],1)],2):_vm._e()]}}])})],1),_c('v-card-actions',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.hpCoverageCode))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }