<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      導入取材日程報告
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="demo">
              <DemoResult :demo="demo" />
              <v-divider />
            </template>

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.scheduleDate)">{{
                      isEmptyValue(creator.scheduleDate) ? '*導入日程予定日を入力してください' : '*導入日程予定日'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.dateTime(creator.scheduleDate)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="導入日程予定日">
                          <validation-provider v-slot="{ errors }" name="導入日程予定日" rules="required">
                            <DateTime label="導入日程予定日" v-model="scheduleDate" :error-messages="errors">
                              <template v-slot="{ click }">
                                <v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">
                                  {{ scheduleDate | dateTime }}
                                </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            scheduleDate = null;
                            creator.scheduleDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.scheduleDate = scheduleDate;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <XCheckLabel :value="!isEmptyValue(creator.memo)">{{
                    isEmptyValue(creator.memo) ? '備考を登録してください' : '備考'
                  }}</XCheckLabel>
                  <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea
                            v-model="memo"
                            label="備考"
                            rows="3"
                            counter
                            :maxlength="1000"
                            :error-messages="errors"
                            clearable
                            outlined
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
    <v-card-actions>
      <span v-if="demo">{{ demo.code }}</span>
      <v-spacer />
      <span v-if="hpCoverage">{{ hpCoverage.code }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import DemoResult from '@/components/demos/Result';
export default {
  components: {
    DemoResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },

    demoCode: {
      type: String,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    canCommit() {
      if (this.isEmptyValue(this.creator.scheduleDate)) return false;
      return true;
    },
  },
  data: () => ({
    //
    initializing: true,

    demo: null,

    scheduleDate: false,
    memo: null,

    creator: {
      loading: false,
      //
      scheduleDate: null,
      memo: null,
    },
  }),
  methods: {
    init() {
      //
      this.expansions = null;
      //
      this.demo = null;

      // 既存データ有無判別用（cardActionに表示させるのみ、処理はPOST内で行わせる)
      this.hpCoverage = null;

      //
      this.scheduleDate = null;
      this.memo = null;

      this.creator = {
        //
        scheduleDate: null,
        memo: null,
      };
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();
      //
      if (this.demoCode != null) {
        //
        this.get('demo', { demoCode: this.demoCode })
          .then((success) => {
            //
            let data = success.data;
            this.demo = data;
            // 取材日程をクリアした場合取材データはすでに持っているのでその判別用に取得(備考はクリアされていないので表示)
            let hpCoverage = data.hpCoverage;
            if (hpCoverage != null) {
              this.hpCoverage = hpCoverage;
              this.creator.memo = hpCoverage.memo;
              this.memo = hpCoverage.memo;
            }
          })
          .catch(() => {
            this.showError('データ取得に失敗しました');
            this.$emit('error');
          });
      }
      this.initializing = false;
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('導入日程を登録してよろしいですか？')) return;
      //
      let request = this.creator;
      request.demoCode = this.demoCode;
      request.scheduleDate = this.convertDate(this.creator.scheduleDate);

      //
      this.post('hpCoverage', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          let data = success.data;
          this.$emit('commit', data.code);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    this.onLoaded();
  },
};
</script>