<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      新規デモ結果報告<strong v-if="isNoApo">(即デモ)</strong>
      <v-spacer />
      <v-btn v-if="isOK" color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
      <v-btn v-else color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommitNotOK">登録</v-btn>
    </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="apo">
              <ApoResult :apo="apo" />
              <v-divider />
            </template>

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.date)">{{
                      isEmptyValue(creator.date) ? '*デモ報告日を入力してください' : '*デモ報告日'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.date(creator.date)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="デモ報告日">
                          <validation-provider v-slot="{ errors }" name="デモ報告日" rules="required">
                            <DateTime label="デモ報告日" v-model="date" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ date | date }} </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            date = null;
                            creator.date = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.date = date;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="apo == null">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.facilityName)">{{
                      isEmptyValue(creator.facilityName) ? '*顧客情報を登録してください' : '*顧客情報'
                    }}</XCheckLabel>
                    <span v-if="creator.facilityName != null" class="ml-4 success--text">
                      <span class="ml-2">{{ creator.facilityName }}</span>
                      <span class="ml-2">{{ creator.facilityPref.name }}</span>
                      <span class="ml-2">{{ creator.facilityAreaName }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-title class="pa-0">
                        <v-spacer />
                        <validation-provider v-slot="{ errors }" name="顧客情報" rules="">
                          <v-switch v-model="newFacility" label="新規顧客として登録" :error-messages="errors" required outlined dense />
                        </validation-provider>
                      </v-card-title>
                      <v-card-text>
                        <v-row v-if="newFacility" align="center">
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="地方" rules="">
                              <v-select
                                v-model="facilityPrefArea"
                                :items="prefAreas"
                                label="地方"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                required
                                outlined
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>

                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="都道府県" rules="required">
                              <v-select
                                v-model="facilityPref"
                                :items="facilityPrefs"
                                label="*都道府県"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || '都道府県 は必須です']"
                                :error-messages="errors"
                                required
                                outlined
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="地域" rules="required">
                              <v-text-field
                                v-model="facilityAreaName"
                                label="*地域"
                                :rules="[(v) => !!v || '地域 は必須です']"
                                counter
                                :maxlength="100"
                                :error-messages="errors"
                                clearable
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="8">
                            <validation-provider v-slot="{ errors }" name="顧客名" rules="required|max:100">
                              <v-text-field
                                v-model="facilityName"
                                label="*顧客名"
                                :rules="[(v) => !!v || '顧客名 は必須です']"
                                counter
                                :maxlength="100"
                                :error-messages="errors"
                                clearable
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="電話番号" rules="required|max:50|phone">
                              <v-text-field
                                v-model="facilityTel"
                                label="*電話番号"
                                :rules="[(v) => !!v || '電話番号 は必須です']"
                                hint="ハイフン抜きで入力してください"
                                counter
                                :maxlength="50"
                                :error-messages="errors"
                                clearable
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row v-else>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="電話番号" rules="required|max:50">
                              <v-text-field
                                v-model="facilityTel"
                                label="*電話番号"
                                :rules="[(v) => !!v || '電話番号 は必須です']"
                                hint="ハイフン抜きで入力してください"
                                counter
                                :maxlength="50"
                                :error-messages="errors"
                                clearable
                                required
                                dense
                                @input="onFacilityTelInput"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="8">
                            <validation-provider v-slot="{ errors }" name="顧客名" rules="required|max:100">
                              <v-select
                                v-model="facility"
                                :items="facilities"
                                item-text="name"
                                item-value="code"
                                label="*顧客名"
                                :rules="[(v) => !!v || '顧客名 は必須です']"
                                :hint="`検索結果:${facilities.length}件`"
                                :error-messages="errors"
                                persistent-hint
                                return-object
                                clearable
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            {
                              facility = null;
                              creator.facility = null;

                              facilityPrefArea = null;
                              facilityTel = null;

                              creator.facilityName = null;
                              creator.facilityPref = {};
                              creator.facilityAreaName = null;
                              creator.facilityTel = null;
                            }
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            {
                              creator.newFacility = newFacility;

                              if (newFacility) {
                                creator.facilityName = facilityName;
                                creator.facilityPref = facilityPref;
                                creator.facilityAreaName = facilityAreaName;
                                creator.facilityTel = facilityTel;
                              } else {
                                creator.facility = facility;
                                creator.facilityName = facility.name;
                                creator.facilityPref = facility.pref;
                                creator.facilityAreaName = facility.areaName;
                                creator.facilityTel = facility.tel;
                              }
                              expansions++;
                            }
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.reportType)">{{
                      isEmptyValue(creator.reportType) ? '*報告区分を選択してください' : '*報告区分'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.demoReportType(creator.reportType)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="報告区分" rules="required">
                          <v-select
                            v-model="reportType"
                            :items="[
                              { text: $options.filters.demoReportType(1), value: 1 },
                              { text: $options.filters.demoReportType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.demoReportType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.demoReportType(1 << 3), value: 1 << 3 },
                              { text: $options.filters.demoReportType(1 << 4), value: 1 << 4 },
                            ]"
                            label="*報告区分"
                            :rules="[(v) => !!v || '報告区分 は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            reportType = null;
                            creator.reportType = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.reportType = reportType;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.resultType)">{{
                      isEmptyValue(creator.resultType) ? '*デモ結果を選択してください' : '*デモ結果'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.demoResultType(creator.resultType)"></span>
                    <span class="ml-4">{{ creator.demoSchedule | dateTime }}</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="デモ結果" rules="required">
                          <v-select
                            v-model="resultType"
                            :items="[
                              { text: $options.filters.demoResultType(1), value: 1 },
                              { text: $options.filters.demoResultType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.demoResultType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.demoResultType(1 << 3), value: 1 << 3 },
                              { text: $options.filters.demoResultType(1 << 4), value: 1 << 4 },
                              { text: $options.filters.demoResultType(1 << 5), value: 1 << 5 },
                              { text: $options.filters.demoResultType(1 << 9), value: 1 << 9 },
                            ]"
                            label="*結果"
                            :rules="[(v) => !!v || '結果 は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>

                        <template v-if="resultType == 1 << 9">
                          <p class="red--text">新しいデモ日程を登録してください。(必須)</p>
                          <XFieldSet label="デモ予定日時">
                            <validation-provider v-slot="{ errors }" name="デモ予定日時" rules="">
                              <DateTime label="デモ予定日時" v-model="demoSchedule" :error-messages="errors">
                                <template v-slot="{ click }"
                                  ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                    >{{ demoSchedule | dateTime }}
                                  </v-btn>
                                </template>
                              </DateTime>
                            </validation-provider>
                          </XFieldSet>
                        </template>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            resultType = null;
                            creator.resultType = null;
                            creator.demoSchedule = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.resultType = resultType;
                            creator.demoSchedule = demoSchedule;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="isNoApo">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel
                      :value="!isEmptyValue(creator.apoBranch) && !isEmptyValue(creator.apointer) && !isEmptyValue(creator.apoTeam)"
                      >{{
                        isEmptyValue(creator.apoBranch) || isEmptyValue(creator.apointer) || isEmptyValue(creator.apoTeam)
                          ? '*アポ情報を登録してください'
                          : '*アポ情報'
                      }}</XCheckLabel
                    >
                    <span
                      v-if="creator.apoBranch != null && creator.apointer != null && creator.apoTeam != null"
                      class="ml-4 success--text"
                    >
                      <span v-if="creator.apoDate != null" class="ml-2">{{ creator.apoDate | date }}</span>
                      <span v-if="creator.apoBranch != null" class="ml-2">{{ creator.apoBranch.name }}</span>
                      <span v-if="creator.apointer != null" class="ml-2">{{ creator.apointer.name }}</span>
                      <span v-if="creator.apoTeam != null" class="ml-2">{{ creator.apoTeam.name }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <p class="text-caption">
                          即デモやアポイント登録を省略してデモ結果を登録する場合は、以下の情報を登録してください。
                          アポインターがないデモの場合、デモ担当者を登録します。
                        </p>
                        <XFieldSet label="*アポ獲得日">
                          <validation-provider v-slot="{ errors }" name="アポ獲得日" rules="required">
                            <DateTime label="アポ獲得日" v-model="apoDate" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ apoDate | date }} </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>

                        <v-row>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="支店" rules="required">
                              <v-select
                                v-model="apoBranch"
                                :items="dataContext.shitens"
                                label="*支店"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || '支店 は必須です']"
                                :error-messages="errors"
                                return-object
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="アポ担当" rules="">
                              <v-select
                                v-model="apointer"
                                :items="apoStaffs"
                                label="アポ担当"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="チーム" rules="">
                              <v-select
                                v-model="apoTeam"
                                :items="apoTeams"
                                label="チーム"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            apoDate = null;
                            apoBranch = null;
                            apointer = null;
                            apoTeam = null;
                            creator.apoDate = null;
                            creator.apoBranch = null;
                            creator.apointer = null;
                            creator.apoTeam = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.apoDate = apoDate;
                            creator.apoBranch = apoBranch;
                            creator.apointer = apointer;
                            creator.apoTeam = apoTeam;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel
                      :value="!isEmptyValue(creator.branch) && !isEmptyValue(creator.demointer) && !isEmptyValue(creator.team)"
                      >{{
                        isEmptyValue(creator.branch) || isEmptyValue(creator.demointer) || isEmptyValue(creator.team)
                          ? '*デモ担当情報を登録してください'
                          : 'デモ担当'
                      }}</XCheckLabel
                    >
                    <span v-if="creator.branch != null && creator.demointer != null" class="ml-4 success--text">
                      <span v-if="creator.branch != null" class="ml-2">{{ creator.branch.name }}</span>
                      <span v-if="creator.demointer != null" class="ml-2">{{ creator.demointer.name }}</span>
                      <span v-if="creator.team != null" class="ml-2">{{ creator.team.name }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="支店" rules="required">
                              <v-select
                                v-model="branch"
                                :items="dataContext.shitens"
                                label="*支店"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || '支店 は必須です']"
                                :error-messages="errors"
                                return-object
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>

                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="デモ担当" rules="required">
                              <v-select
                                v-model="demointer"
                                :items="staffs"
                                label="*デモ担当"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || 'デモ担当 は必須です']"
                                :error-messages="errors"
                                return-object
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="チーム" rules="required">
                              <v-select
                                v-model="team"
                                :items="teams"
                                label="*チーム"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || 'チーム は必須です']"
                                :error-messages="errors"
                                return-object
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            branch = null;
                            demointer = null;
                            team = null;
                            creator.branch = null;
                            creator.demointer = null;
                            creator.team = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.branch = branch;
                            creator.demointer = demointer;
                            creator.team = team;
                            $nextTick(() => {
                              expansions++;
                            });
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="isOK">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="creator.systemTypes.length > 0">{{
                      creator.length == 0 ? '*システムを登録してください' : '*システム'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text">{{
                      creator.systemTypes.map((a) => $options.filters.demoSystemType(a)) | implode(',')
                    }}</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="システム" rules="required">
                          <v-select
                            v-model="systemTypes"
                            :items="[
                              { text: $options.filters.demoSystemType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.demoSystemType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.demoSystemType(1 << 3), value: 1 << 3 },
                              { text: $options.filters.demoSystemType(1 << 4), value: 1 << 4 },
                              { text: $options.filters.demoSystemType(1 << 6), value: 1 << 6 },
                              { text: $options.filters.demoSystemType(1 << 7), value: 1 << 7 },
                              { text: $options.filters.demoSystemType(1 << 8), value: 1 << 8 },
                            ]"
                            label="*システム"
                            :rules="[(v) => !!v || 'システムは必須です']"
                            :error-messages="errors"
                            multiple
                            required
                            outlined
                            dense
                            style="width: 300px"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            systemTypes = [];
                            creator.systemTypes = [];
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.systemTypes = systemTypes;
                            $nextTick(() => {
                              expansions++;
                            });
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="isOK">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.contractType)">{{
                      isEmptyValue(creator.contractType) ? '受注区分を登録してください' : '受注区分'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.demoContractType(creator.contractType)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="受注区分" rules="required">
                          <v-select
                            v-model="contractType"
                            :items="[
                              { text: $options.filters.demoContractType(1), value: 1 },
                              { text: $options.filters.demoContractType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.demoContractType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.demoContractType(1 << 3), value: 1 << 3 },
                            ]"
                            label="*受注区分"
                            :rules="[(v) => !!v || '受注区分は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            contractType = null;
                            creator.contractType = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.contractType = contractType;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="isOK">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.saleType) || !isEmptyValue(creator.sales)">
                      *売上区分,受注金額を登録してください
                    </XCheckLabel>
                    <span v-if="!isEmptyValue(creator.saleType)" class="ml-4 success--text"
                      >*売上区分:{{ creator.saleType | demoSaleType }}
                    </span>
                    <span v-if="creator.term > 0" class="ml-4 success--text"> 期間:{{ creator.term | monthToYear }} </span>
                    <span v-if="saleType == 1" class="ml-4 success--text"> 暫定料率:{{ creator.rate }}&#37; </span>
                    <span v-if="!isEmptyValue(creator.sales)" class="ml-4 success--text"> 受注金額:{{ creator.sales | priceJP }} </span>
                    <span v-if="!isEmptyValue(creator.residualBonds)" class="ml-4 success--text"
                      >残債:{{ creator.residualBonds | priceJP }}
                    </span>
                    <span v-if="!isEmptyValue(creator.underwritingBonds)" class="ml-4 success--text"
                      >他社買取:{{ creator.underwritingBonds | priceJP }}
                    </span>
                    <span v-if="!isEmptyValue(creator.complimentaryBonds)" class="ml-4 success--text"
                      >サービス品:{{ creator.complimentaryBonds | priceJP }}
                    </span>
                    <span v-if="!isEmptyValue(creator.salesRecognitionScheduleDate)" class="ml-4 success--text"
                      >売上計上予定月:{{ creator.salesRecognitionScheduleDate | dateMonth }}
                    </span>
                    <span v-if="!isEmptyValue(creator.salesRecognitionScheduleDate)" class="ml-4 success--text"
                      >売上計上予定月:{{ creator.salesRecognitionScheduleDate | dateMonth }}
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <div class="d-flex justify-space-around">
                          <validation-provider v-slot="{ errors }" name="売上区分" rules="required">
                            <v-select
                              v-model="saleType"
                              :items="[
                                { text: $options.filters.demoSaleType(1), value: 1 },
                                { text: $options.filters.demoSaleType(1 << 1), value: 1 << 1 },
                                { text: $options.filters.demoSaleType(1 << 2), value: 1 << 2 },
                                { text: $options.filters.demoSaleType(1 << 3), value: 1 << 3 },
                                { text: $options.filters.demoSaleType(1 << 4), value: 1 << 4 },
                              ]"
                              label="*売上区分"
                              :rules="[(v) => !!v || '売上区分は必須です']"
                              :error-messages="errors"
                              required
                              outlined
                              dense
                            />
                          </validation-provider>

                          <validation-provider v-slot="{ errors }" name="期間" rules="required">
                            <v-select
                              v-model="term"
                              :items="[
                                { text: $options.filters.monthToYear(12), value: 12 },
                                { text: $options.filters.monthToYear(60), value: 60 },
                              ]"
                              label="*期間"
                              :rules="[(v) => !!v || '期間は必須です']"
                              :error-messages="errors"
                              required
                              outlined
                              dense
                            />
                          </validation-provider>

                          <template v-if="saleType == 1">
                            <validation-provider v-slot="{ errors }" name="暫定料率" rules="required">
                              <v-combobox
                                class="ml-2"
                                v-model.number="rate"
                                :items="['1.83', '1.82']"
                                label="*暫定料率"
                                :rules="[(v) => !!v || '暫定料率 は必須です']"
                                counter
                                :maxlength="5"
                                :error-messages="errors"
                                required
                                dense
                              ></v-combobox>
                            </validation-provider>
                          </template>
                        </div>

                        <XFieldSet class="mb-4" label="金額">
                          <v-card flat>
                            <v-card-title>
                              <validation-provider
                                v-slot="{ errors }"
                                name="受注金額"
                                :rules="(saleType & (1 | (1 << 1) | (1 << 2))) != 0 ? 'required' : ''"
                              >
                                <v-text-field
                                  type="number"
                                  v-model.number="sales"
                                  label="受注金額(税抜)"
                                  hint="リース・レンタル・現金の場合必須です"
                                  persistent-hint
                                  clearable
                                  dense
                                  suffix="円"
                                  counter
                                  :maxlength="10"
                                  :error-messages="errors"
                                />
                              </validation-provider>

                              <v-spacer />

                              <validation-provider v-slot="{ errors }" name="残債" rules="">
                                <v-text-field
                                  class="ml-4"
                                  type="number"
                                  v-model.number="residualBonds"
                                  label="残債"
                                  counter
                                  :maxlength="10"
                                  :error-messages="errors"
                                  clearable
                                  dense
                                  suffix="円"
                                />
                              </validation-provider>
                              <validation-provider v-slot="{ errors }" name="他社買取" rules="">
                                <v-text-field
                                  class="ml-4"
                                  type="number"
                                  v-model.number="underwritingBonds"
                                  label="他社買取"
                                  counter
                                  :maxlength="10"
                                  :error-messages="errors"
                                  clearable
                                  dense
                                  suffix="円"
                                />
                              </validation-provider>

                              <validation-provider v-slot="{ errors }" name="サービス品" rules="">
                                <v-text-field
                                  class="ml-4"
                                  type="number"
                                  v-model.number="complimentaryBonds"
                                  label="サービス品"
                                  counter
                                  :maxlength="10"
                                  :error-messages="errors"
                                  clearable
                                  dense
                                  suffix="円"
                                />
                              </validation-provider>
                            </v-card-title>
                            <v-card-actions>
                              <p class="h4-text">
                                <strong>見込み金額</strong> :{{ $options.filters.calcLeaseAmount(sales, saleType, rate, term) | priceJP }}
                              </p>
                            </v-card-actions>
                          </v-card>
                        </XFieldSet>

                        <p>
                          <XFieldSet label="売上計上予定月">
                            <validation-provider v-slot="{ errors }" name="売上計上予定月" rules="">
                              <DateTime
                                label="売上計上予定月"
                                v-model="salesRecognitionScheduleDate"
                                hideTime
                                month
                                :error-messages="errors"
                              >
                                <template v-slot="{ click }"
                                  ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                    >{{ salesRecognitionScheduleDate | dateMonth }}
                                  </v-btn>

                                  <v-btn icon @click="salesRecognitionScheduleDate = null"><v-icon v-text="'mdi-close'" /> </v-btn>
                                </template>
                              </DateTime>
                            </validation-provider>
                          </XFieldSet>
                        </p>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            saleType = null;
                            sales = null;
                            residualBonds = null;
                            underwritingBonds = null;
                            complimentaryBonds = null;

                            term = null;
                            rate = null;
                            salesRecognitionScheduleDate = null;

                            creator.saleType = null;
                            creator.sales = null;
                            creator.residualBonds = null;
                            creator.underwritingBonds = null;
                            creator.complimentaryBonds = null;
                            creator.term = null;
                            creator.rate = null;
                            creator.salesRecognitionScheduleDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            // 売上区分がリース以外は料率をnullにする
                            if (saleType != 1) rate = null;
                            //
                            creator.saleType = saleType;
                            creator.sales = sales;
                            creator.residualBonds = residualBonds;
                            creator.underwritingBonds = underwritingBonds;
                            creator.complimentaryBonds = complimentaryBonds;
                            creator.term = term;
                            creator.rate = rate;
                            creator.salesRecognitionScheduleDate = salesRecognitionScheduleDate;

                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="isOK">
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.initialCost)">{{
                      isEmptyValue(creator.initialCost) ? '初期費用(税抜)を登録してください' : '初期費用(税抜)'
                    }}</XCheckLabel>
                    <span v-if="creator.initialCost != null" class="ml-4 success--text">
                      <span class="ml-2">{{ creator.initialCost | priceJP }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="初期費用" rules="">
                          <v-text-field
                            type="number"
                            v-model.number="initialCost"
                            label="初期費用(税抜)"
                            counter
                            :maxlength="10"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="円"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            initialCost = null;
                            creator.initialCost = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.initialCost = initialCost;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="isOK">
                <v-expansion-panel-header>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>オプション:</v-list-item-title>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.cloud != null"
                              >クラウド:<strong v-text="$options.filters.metaCloud(creator.meta.cloud.id)"
                            /></span>
                          </v-col>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.shuukyaku_support != null"
                              >集客サポート:<strong v-text="$options.filters.metaShuukyaku_support(creator.meta.shuukyaku_support.id)" />
                            </span>
                          </v-col>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.kaikei_support != null"
                              >会計サポート:<strong v-text="$options.filters.metaKaikei_support(creator.meta.kaikei_support.id)"
                            /></span>
                          </v-col>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.katyao != null"
                              >かっちゃお:<strong v-text="$options.filters.metaKatyao(creator.meta.katyao.id)"
                            /></span>
                          </v-col>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.hp != null"
                              >HP:<strong v-text="$options.filters.metaHp(creator.meta.hp.id)"
                            /></span>
                          </v-col>
                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="creator.meta.yokoku_hp != null"
                              >予告HP:<strong v-text="$options.filters.metaYokoku_hp(creator.meta.yokoku_hp.id)"
                            /></span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" md="4">
                            <span
                              class="ml-2"
                              v-if="Array.isArray(creator.meta.asunaroPlusType) && creator.meta.asunaroPlusType.length > 0"
                            >
                              あすなろプラス:
                              <strong class="ml-2" v-for="(asunaroPlusType, index) of creator.meta.asunaroPlusType" :key="index">
                                <!-- Fixme implodeでよいが、記述が長くなったため以下でトライ -->
                                <template v-if="index != 0">,</template>
                                {{ asunaroPlusType | asunaroPlusType }}
                              </strong>
                            </span>
                          </v-col>

                          <v-col cols="6" md="4">
                            <span class="ml-2" v-if="Array.isArray(creator.meta.yadoPlusType) && creator.meta.yadoPlusType.length > 0">
                              宿プラス+:
                              <strong class="ml-2" v-for="(yadoPlusType, index) of creator.meta.yadoPlusType" :key="index">
                                <!-- Fixme implodeでよいが、記述が長くなったため以下でトライ -->
                                <template v-if="index != 0">,</template>
                                {{ yadoPlusType | yadoPlusType }}
                              </strong>
                            </span>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6" md="4">
                            <validation-provider v-slot="{ errors }" name="クラウド" rules="">
                              <v-select
                                v-model="meta.cloud"
                                :items="dataContext.clouds"
                                label="クラウド"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="6" md="4">
                            <validation-provider v-slot="{ errors }" name="集客サポート" rules="">
                              <v-select
                                v-model="meta.shuukyaku_support"
                                :items="dataContext.shuukyaku_supports"
                                label="集客サポート"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="6" md="4">
                            <validation-provider v-slot="{ errors }" name="会計サポート" rules="">
                              <v-select
                                v-model="meta.kaikei_support"
                                :items="dataContext.kaikei_supports"
                                label="会計サポート"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="6" md="4">
                            <validation-provider v-slot="{ errors }" name="かっちゃお" rules="">
                              <v-select
                                v-model="meta.katyao"
                                :items="dataContext.katyaos"
                                label="かっちゃお"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="6" md="4">
                            <validation-provider v-slot="{ errors }" name="HP" rules="">
                              <v-select
                                v-model="meta.hp"
                                :items="dataContext.hps"
                                label="HP"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="6" md="4">
                            <validation-provider v-slot="{ errors }" name="予告HP" rules="">
                              <v-select
                                v-model="meta.yokoku_hp"
                                :items="dataContext.yokoku_hps"
                                label="予告HP"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <validation-provider v-slot="{ errors }" name="あすなろプラス" rules="">
                              <v-select
                                v-model="meta.asunaroPlusType"
                                :items="[
                                  { text: $options.filters.asunaroPlusType(AsunaroPlusTypes.Order), value: AsunaroPlusTypes.Order },
                                  { text: $options.filters.asunaroPlusType(AsunaroPlusTypes.Kitchen), value: AsunaroPlusTypes.Kitchen },
                                ]"
                                label="あすなろプラス"
                                multiple
                                :error-messages="errors"
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <validation-provider v-slot="{ errors }" name="宿プラス+" rules="">
                              <v-select
                                v-model="meta.yadoPlusType"
                                :items="[
                                  { text: $options.filters.yadoPlusType(YadoPlusTypes.Ryosenkikaku), value: YadoPlusTypes.Ryosenkikaku },
                                ]"
                                label="宿プラス+"
                                multiple
                                :error-messages="errors"
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            meta.cloud = null;
                            meta.shuukyaku_support = null;
                            meta.kaikei_support = null;
                            meta.katyao = null;
                            meta.katyao = null;
                            meta.hp = null;
                            meta.yadoPlusType = [];
                            creator.meta.cloud = null;
                            creator.meta.shuukyaku_support = null;
                            creator.meta.kaikei_support = null;
                            creator.meta.katyao = null;
                            creator.meta.hp = null;
                            creator.meta.yokoku_hp = null;
                            creator.meta.asunaroPlusType = [];
                            creator.meta.yadoPlusType = [];
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.meta = meta;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <XCheckLabel :value="!isEmptyValue(creator.memo)">{{
                    isEmptyValue(creator.memo) ? '備考を登録してください' : '備考'
                  }}</XCheckLabel>
                  <span class="d-inline-block text-truncate" style="max-width: 120px">{{ creator.memo }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea
                            v-model="memo"
                            label="備考"
                            rows="3"
                            counter
                            :maxlength="1000"
                            :error-messages="errors"
                            clearable
                            outlined
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
  </v-card>
</template>

<script>
import ApoResult from '@/components/apos/Result';

import { AsunaroPlusTypes, YadoPlusTypes } from '@/types';

export default {
  components: {
    ApoResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    observer: {
      type: Object,
      required: true,
    },

    apoCode: {
      type: String,
    },
  },
  computed: {
    AsunaroPlusTypes() {
      return AsunaroPlusTypes;
    },
    YadoPlusTypes() {
      return YadoPlusTypes;
    },

    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    isNoApo() {
      return this.apoCode == null;
    },
    isOK() {
      return this.creator.resultType == 1 << 1;
    },
    isRedemo() {
      return this.creator.resultType == 1 << 9;
    },
    canCommit() {
      if (this.isOK) {
        if (this.isEmptyValue(this.creator.date)) return false;
        if (this.isEmptyValue(this.creator.facilityName)) return false;
        if (this.isEmptyValue(this.creator.facilityTel)) return false;
        if (this.isEmptyValue(this.creator.reportType)) return false;
        if (this.isEmptyValue(this.creator.resultType)) return false;
        if (this.isEmptyValue(this.creator.systemTypes.length)) return false;
        if (this.isEmptyValue(this.creator.contractType)) return false;
        if (this.isEmptyValue(this.creator.saleType)) return false;
        if (this.isEmptyValue(this.creator.term)) return false;

        if (this.isEmptyValue(this.creator.branch)) return false;
        if (this.isEmptyValue(this.creator.demointer)) return false;
        if (this.isEmptyValue(this.creator.team)) return false;
        // 金額がない受注もありうる（すけさんのみなど）
        // リース・レンタル・現金の場合金額必須
        if (this.isEmptyValue(this.creator.sales)) {
          if (this.creator.saleType == 1) return false; // リース
          if (this.creator.saleType == 1 << 1) return false; // レンタル
          if (this.creator.saleType == 1 << 2) return false; // 現金
        }
      } else {
        if (this.isEmptyValue(this.creator.date)) return false;
        if (this.isEmptyValue(this.creator.facilityName)) return false;
        if (this.isEmptyValue(this.creator.facilityTel)) return false;
        if (this.isEmptyValue(this.creator.reportType)) return false;
        if (this.isEmptyValue(this.creator.resultType)) return false;
        if (this.isEmptyValue(this.creator.branch)) return false;
        if (this.isEmptyValue(this.creator.demointer)) return false;
        if (this.isEmptyValue(this.creator.team)) return false;
      }

      if (this.isRedemo) {
        if (this.isEmptyValue(this.creator.demoSchedule)) return false;
      }

      // 即デモでもアポは自分自身で入れるため（アポ引継ぎでも以下の情報は必ずはいる)
      if (this.isEmptyValue(this.creator.apoDate)) return false;
      if (this.isEmptyValue(this.creator.apoBranch)) return false;
      // アポ情報は日付・支店は必須だが担当者・チームは不要
      return true;
    },

    apoStaffs() {
      let staffs = this.dataContext.staffs;
      if (this.apoBranch != null) {
        staffs = staffs.filter((a) => a.branchCode == this.apoBranch.id);
      }
      // 指定なしを先頭に加える
      staffs.unshift({
        id: null,
        code: null,
        name: '指定なし',
      });
      return staffs;
    },

    apoTeams() {
      let teams = [];
      if (this.apoBranch != null) teams = this.apoBranch.teams;

      // 指定なしを先頭に加える
      teams.unshift({
        id: null,
        code: null,
        name: '指定なし',
      });

      return teams;
    },

    staffs() {
      let staffs = this.dataContext.staffs;
      if (this.branch != null) {
        staffs = staffs.filter((a) => a.branchCode == this.branch.id);
      }
      return staffs;
    },

    teams() {
      if (this.branch != null) return this.branch.teams;
      return [];
    },

    facilityPrefs() {
      let prefs = this.dataContext.prefs;
      if (this.facilityPrefArea != null) {
        prefs = prefs.filter((a) => this.facilityPrefArea.prefIds.includes(a.prefID));
      }
      return prefs;
    },
  },
  data: () => ({
    //
    initializing: true,

    apo: null,
    facilities: [],
    //

    expansions: null, // multipleではいので単体
    //
    newFacility: false,

    date: false,

    facilityPrefArea: null,

    facilityName: null,
    facilityAreaName: null,
    facilityTel: null,
    facilityPref: null,

    facility: null,
    // facility: {
    //   loading: false,
    //   code: null,
    //   name: null,
    //   pref: {},
    // },

    //
    reportType: 1,
    resultType: null,
    systemTypes: [],
    contractType: null,
    saleType: null,
    term: null,
    rate: null,

    branch: null,

    apoBranch: null,
    apoDate: null,
    apointer: null,
    apoTeam: null,

    demointer: null,
    team: null,

    salesRecognitionScheduleDate: null,

    residualBonds: null,
    underwritingBonds: null,
    complimentaryBonds: null,

    sales: null,
    initialCost: null,

    meta: {
      cloud: null,
      shuukyaku_support: null,
      kaikei_support: null,
      katyao: null,
      hp: null,
      yokoku_hp: null,
      asunaroPlusType: [], // enum:multiple
      yadoPlusType: [], // enum:multiple
    },

    memo: null,

    // 再デモ用
    demoSchedule: null,

    creator: {
      loading: false,
      //
      newFacility: false,
      //
      date: null,
      //
      facilityName: null,
      facilityAreaName: null,
      facilityTel: null,
      facilityPref: null,

      facility: null,
      // facility: {
      //   code: null,
      //   name: null,
      //   pref: {},
      // },

      //
      reportType: null,
      resultType: null,
      systemTypes: [],
      contractType: null,
      saleType: null,

      term: null,
      rate: null,

      apoBranch: null,
      apoDate: null,
      apointer: null,
      apoTeam: null,

      branch: null,
      demointer: null,
      team: null,

      salesRecognitionScheduleDate: null,
      sales: null,

      residualBonds: null,
      underwritingBonds: null,
      complimentaryBonds: null,

      initialCost: null,

      meta: {
        cloud: null,
        shuukyaku_support: null,
        kaikei_support: null,
        katyao: null,
        hp: null,
        yokoku_hp: null,
        asunaroPlusType: [], // enum:multiple
        yadoPlusType: [], // enum:multiple
      },

      memo: null,

      // 再デモ用
      demoSchedule: null,
    },
  }),
  methods: {
    init() {
      //
      this.expansions = null;
      //
      this.apo = null;
      //
      this.newFacility = false;

      this.date = new Date();

      this.facilityName = null;
      this.facilityAreaName = null;
      this.facilityTel = null;
      this.facilityPref = null;

      this.facility = null;
      // this.facility = {
      //   loading: false,
      //   code: null,
      //   name: null,
      //   pref: {},
      // };
      //
      this.reportType = 1;
      this.resultType = null;
      this.systemTypes = [];
      this.contractType = null;
      this.saleType = null;

      this.term = null;
      this.rate = null;

      this.apoBranch = null;
      this.apoDate = null;
      this.apointer = null;
      this.apoTeam = null;

      this.salesRecognitionScheduleDate = null;
      this.sales = null;

      this.residualBonds = null;
      this.underwritingBonds = null;
      this.complimentaryBonds = null;

      this.initialCost = null;

      this.meta = {
        cloud: null,
        shuukyaku_support: null,
        kaikei_support: null,
        katyao: null,
        hp: null,
        yokoku_hp: null,
        asunaroPlusType: [], // enum:multiple
        yadoPlusType: [], // enum:multiple
      };

      this.memo = null;

      // 再デモ用
      this.demoSchedule = null;

      this.creator = {
        //
        newFacility: false,
        //
        date: null,
        //
        facilityName: null,
        facilityAreaName: null,
        facilityTel: null,
        facilityPref: null,

        facility: null,
        // facility: {
        //   code: null,
        //   name: null,
        //   pref: {},
        // },

        //
        reportType: null,
        resultType: null,
        systemTypes: [],
        contractType: null,
        saleType: null,
        term: null,
        rate: null,

        apoDate: null,
        apoBranch: null,
        apointer: null,
        apoTeam: null,

        branch: null,
        demointer: null,
        team: null,

        salesRecognitionScheduleDate: null,
        sales: null,

        residualBonds: null,
        underwritingBonds: null,
        complimentaryBonds: null,

        initialCost: null,

        meta: {
          cloud: null,
          shuukyaku_support: null,
          kaikei_support: null,
          katyao: null,
          hp: null,
          yokoku_hp: null,
          asunaroPlusType: [], // enum:multiple
          yadoPlusType: [], // enum:multiple
        },

        memo: null,

        // 再デモ用
        demoSchedule: null,
      };

      // ユーザ情報から初期選択状態
      if (this.$store.getters.user.isLogined) {
        this.get('employee', { employeeCode: this.$store.getters.user.code }).then((success) => {
          let data = success.data;

          let branch = data.branch;
          let team = data.team;

          //
          this.demointer = {
            id: data.code,
            name: data.name,
          };
          //
          this.apointer = {
            id: data.code,
            name: data.name,
          };

          if (branch != null) {
            this.branch = {
              id: branch.code,
              name: branch.name,
              teams: branch.teams,
            };

            this.apoBranch = {
              id: branch.code,
              name: branch.name,
              teams: branch.teams,
            };
          }

          if (team != null) {
            // 所属チームがbranch.teamsにいなければ足す
            if (this.branch.teams.filter((a) => a.id == team.id).length == 0) {
              // インスタンスを生成して設定
              this.branch.teams.push({
                id: team.id,
                name: team.name,
              });
            }

            this.team = {
              id: team.code,
              name: team.name,
            };

            this.apoTeam = {
              id: team.code,
              name: team.name,
            };
          }
        });
      }
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();
      //
      if (!this.isNoApo) {
        //
        this.get('apo', { apoCode: this.apoCode })
          .then((success) => {
            //
            let data = success.data;
            this.apo = data;

            // アポ情報を引き継ぐ
            let apoBranch = null;

            if (this.apo.branch != null) {
              apoBranch = {
                id: this.apo.branch.code,
                name: this.apo.branch.name,
                teams: this.apo.branch.teams,
              };
            }

            let apoDate = this.$moment(this.apo.date).format();

            let apointer = null;
            if (this.apo.apointer != null) {
              apointer = {
                id: this.apo.apointer.code,
                name: this.apo.apointer.name,
              };
            }

            let apoTeam = null;
            if (this.apo.team != null) {
              apoTeam = {
                id: this.apo.team.code,
                code: this.apo.team.code,
                name: this.apo.team.name,
              };
            }

            this.apoBranch = apoBranch;
            this.apoDate = apoDate;
            this.apointer = apointer;
            this.apoTeam = apoTeam;

            this.creator.apoBranch = apoBranch;
            this.creator.apoDate = apoDate;
            this.creator.apointer = apointer;
            this.creator.apoTeam = apoTeam;

            // 再デモ用
            this.demoSchedule = data.demoSchedule;
            this.creator.demoSchedule = data.demoSchedule;

            this.creator.facility = data.facility;
            this.creator.facilityName = data.facility.name;
            this.creator.facilityTel = data.facility.tel;
            this.creator.facilityPref = data.facility.pref;
            this.creator.facilityAreaName = data.facility.areaName;
          })
          .catch(() => {
            this.showError('データ取得に失敗しました');
            this.$emit('error');
          });
      }
      this.initializing = false;
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('デモ完了(受注)登録してよろしいですか？')) return;
      //

      let request = this.creator;
      request.apoCode = this.apoCode;
      request.date = this.convertDate(this.creator.date);
      request.demoSchedule = this.convertDate(this.creator.demoSchedule);
      request.salesRecognitionScheduleDate = this.convertDate(this.creator.salesRecognitionScheduleDate);

      // num->enum(s)
      request.meta.asunaroPlusType = this.convertAr2Bit(this.creator.meta.asunaroPlusType);
      request.meta.yadoPlusType = this.convertAr2Bit(this.creator.meta.yadoPlusType);

      // nullable
      request.initialCost = this.convertNumber(request.initialCost);
      request.rate = this.convertNumber(request.rate);
      request.sales = this.convertNumber(request.sales);
      request.residualBonds = this.convertNumber(request.residualBonds);
      request.underwritingBonds = this.convertNumber(request.underwritingBonds);
      request.complimentaryBonds = this.convertNumber(request.complimentaryBonds);
      //
      this.post('demo', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          let data = success.data;
          this.$emit('commit', data.code);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    /**
     * フォーム決定イベント
     */
    async onCommitNotOK() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //

      let request = this.creator;
      request.apoCode = this.apoCode;
      request.date = this.convertDate(this.creator.date);
      request.demoSchedule = this.convertDate(this.creator.demoSchedule);

      //
      this.post('demo/notOK', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          let data = success.data;
          this.$emit('commit', data.code);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    onCancelClicked() {
      this.$emit('cancel');
    },

    onFacilityTelInput() {
      if (this.facilityTel.length <= 2) return;

      this.get('search/facility/tel', { tel: this.facilityTel }).then((success) => {
        //
        let data = success.data;
        this.facilities = data;
      });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>