var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._t("close"),_c('v-spacer'),_vm._v(" 事前審査提出 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommit}},[_vm._v("登録")])],2),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('div',[(_vm.apo)?[_c('ApoResult',{attrs:{"apo":_vm.apo}}),_c('v-divider')]:_vm._e(),(_vm.demo)?[_c('DemoResult',{attrs:{"demo":_vm.demo}}),_c('v-divider')]:_vm._e(),_c('v-expansion-panels',{model:{value:(_vm.expansions),callback:function ($$v) {_vm.expansions=$$v},expression:"expansions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('span',[_c('XCheckLabel',{attrs:{"value":_vm.creator.isSubmitPreExaminationDate}},[_vm._v("事前審査提出: ")])],1),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.creator.isSubmitPreExaminationDate ? '提出' : '未提出'))])])]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-checkbox',{attrs:{"label":"提出"},model:{value:(_vm.isSubmitPreExaminationDate),callback:function ($$v) {_vm.isSubmitPreExaminationDate=$$v},expression:"isSubmitPreExaminationDate"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.isSubmitPreExaminationDate = false;
                          _vm.creator.isSubmitPreExaminationDate = false;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.isSubmitPreExaminationDate = _vm.isSubmitPreExaminationDate;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('span',[_vm._v("備考: ")]),_c('span',{staticClass:"d-inline-block text-truncate ml-2",staticStyle:{"max-width":"120px"}},[_vm._v(_vm._s(_vm.creator.memo))])])]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","rows":"3","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.memo = null;
                          _vm.creator.memo = null;}}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.memo = _vm.memo;
                          _vm.expansions = null;}}},[_vm._v("設定")])],1)],1)]}}],null,true)})],1)],1)],1)],2):_vm._e()]}}])})],1),_c('v-card-actions',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.demoCode))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }