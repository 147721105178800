var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._t("close"),_c('v-spacer'),_vm._v(" デモ日程確認登録 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommit}},[_vm._v("登録")])],2),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('div',[_c('ApoResult',{attrs:{"apo":_vm.apo}}),_c('v-divider'),_c('v-expansion-panels',{model:{value:(_vm.expansions),callback:function ($$v) {_vm.expansions=$$v},expression:"expansions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":_vm.creator.confirmStates != 1}},[(_vm.creator.confirmStates == 1)?_c('span',[_vm._v("*デモ日程を確認")]):(_vm.creator.confirmStates == _vm.ConfirmStates.OK)?_c('span',[_vm._v("*確認済み "+_vm._s(_vm._f("dateTime")(_vm.creator.demoSchedule)))]):(_vm.creator.confirmStates == _vm.ConfirmStates.Change)?_c('span',[_vm._v("*日程変更 "+_vm._s(_vm._f("dateTime")(_vm.creator.demoSchedule)))]):(_vm.creator.confirmStates == _vm.ConfirmStates.Cancel)?_c('span',[_vm._v("*キャンセル")]):_vm._e()])],1)]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"デモ日程","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"dense":"","column":""},model:{value:(_vm.confirmStates),callback:function ($$v) {_vm.confirmStates=$$v},expression:"confirmStates"}},[_c('v-radio',{attrs:{"label":"確認済み","value":_vm.ConfirmStates.OK}}),_c('v-radio',{attrs:{"label":"日程変更","value":_vm.ConfirmStates.Change}}),_c('v-radio',{attrs:{"label":"キャンセル","value":_vm.ConfirmStates.Cancel}})],1)]}}],null,true)})],1),(_vm.confirmStates == _vm.ConfirmStates.OK)?[_c('p',[_vm._v("デモ時間の変更がある場合は以下を修正してください")]),_c('v-time-picker',{model:{value:(_vm.demoScheduleTime),callback:function ($$v) {_vm.demoScheduleTime=$$v},expression:"demoScheduleTime"}})]:_vm._e(),(_vm.confirmStates == _vm.ConfirmStates.Change)?[_c('p',[_vm._v("変更後の日程を登録してください。")]),_c('XFieldSet',{attrs:{"label":"デモ予定日時"}},[_c('validation-provider',{attrs:{"name":"デモ予定日時","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"デモ予定日時","error-messages":errors},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("dateTime")(_vm.demoSchedule))+" ")])]}}],null,true),model:{value:(_vm.demoSchedule),callback:function ($$v) {_vm.demoSchedule=$$v},expression:"demoSchedule"}})]}}],null,true)})],1)]:_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.confirmStates = null;
                          _vm.creator.confirmStates = false;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid || _vm.confirmStates == 1},on:{"click":function($event){_vm.creator.confirmStates = _vm.confirmStates;
                          //
                          if (_vm.confirmStates == _vm.ConfirmStates.OK) {
                            _vm.creator.demoSchedule = _vm.$moment((_vm.demoScheduleDate + " " + _vm.demoScheduleTime)).toDate();
                            _vm.apo.demoSchedule = _vm.$moment((_vm.demoScheduleDate + " " + _vm.demoScheduleTime)).toDate();
                          } else if (_vm.confirmStates == _vm.ConfirmStates.Change) {
                            _vm.creator.demoSchedule = _vm.demoSchedule;
                            _vm.apo.demoSchedule = _vm.demoSchedule;
                          }

                          _vm.expansions++;}}},[_vm._v("設定")])],1)],2)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.memo)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.memo) ? '備考を登録してください' : '備考'))]),_c('span',{staticClass:"d-inline-block text-truncate ml-2",staticStyle:{"max-width":"120px"}},[_vm._v(_vm._s(_vm.creator.memo))])],1),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.memo = null;
                          _vm.creator.memo = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.memo = _vm.memo;
                          _vm.expansions = null;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1)],1)],1):_vm._e()]}}])})],1),_c('v-card-actions',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.apoCode))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }