<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="text-overline">取材情報</div>
        <span>
          取材日 <strong>{{ hpCoverage.date | date }}</strong>
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="3">取材日</v-col>
              <v-col cols="9">{{ hpCoverage.date | date }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="3">リース用紙回収区分</v-col>
              <v-col cols="9">{{ hpCoverage.leasePaymentStartState | leasePaymentStartState }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="3">時間</v-col>
              <v-col cols="9">
                移動:<span class="ml-2">{{ hpCoverage.moveTime }}時間</span> 取材:<span class="ml-2"
                  >{{ hpCoverage.coverageTime }}時間</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">取材経費</v-col>
              <v-col cols="9">{{ hpCoverage.cost | priceJP }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="3">導入・取材・構成担当</v-col>
              <v-col cols="9">
                <span>
                  <div>
                    導入:
                    <span v-if="hpCoverage.setuper != null" class="ml-2">{{ hpCoverage.setuper.name }}</span>
                  </div>
                  <div>
                    取材:
                    <span v-if="hpCoverage.coverager != null" class="ml-2">{{ hpCoverage.coverager.name }}</span>
                  </div>
                  <div>
                    構成:
                    <span v-if="hpCoverage.submiter != null" class="ml-2">{{ hpCoverage.submiter.name }}</span>
                  </div>
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3">構成提出予定日</v-col>
              <v-col cols="9">{{ hpCoverage.submitscheduleDate | date }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="3">その他</v-col>
              <v-col cols="9">
                <ul>
                  <li>{{ hpCoverage.meta.addCoverage ? '追加取材' : '' }}</li>
                  <li>{{ hpCoverage.meta.useCloud ? 'クラウド利用' : '' }}</li>
                  <li>{{ hpCoverage.meta.domain | domain }}</li>
                  <li>{{ hpCoverage.meta.domainName }}{{ hpCoverage.meta.domainSuffix }}</li>
                </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">備考</v-col>
              <v-col cols="9" v-html="$options.filters.md(hpCoverage.memo)"></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    hpCoverage: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  data: () => ({}),
  methods: {},
  watch: {},
  mounted() {
    // this.onLoaded();
  },
};
</script>