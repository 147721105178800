<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      アポ編集
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" v-ripple @click="onCommit">登録</v-btn>
    </v-card-title>

    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="true">*顧客情報</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.apoType(creator.apoType)"></span>
                    <span v-if="facilityName != null" class="ml-4 success--text">
                      <span class="ml-2">{{ facilityName }}</span>
                      <span class="ml-2">{{ facilityPref.name }}</span>
                      <span class="ml-2">{{ facilityAreaName }}</span>
                      <span class="ml-2">{{ facilityTel }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-alert border="right" colored-border type="error" elevation="2">顧客情報の変更はできません</v-alert>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.apoType)">{{
                      isEmptyValue(creator.apoType) ? '*顧客(報告区分)を入力してください' : '*顧客(報告区分)'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.apoType(creator.apoType)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="顧客(報告区分)" rules="required">
                          <v-select
                            v-model="apoType"
                            :items="[
                              { text: $options.filters.apoType(1), value: 1 },
                              { text: $options.filters.apoType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.apoType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.apoType(1 << 3), value: 1 << 3 },

                              { text: $options.filters.apoType(1 << 4), value: 1 << 4 },
                              { text: $options.filters.apoType(1 << 5), value: 1 << 5 },
                              { text: $options.filters.apoType(1 << 9), value: 1 << 9 },
                            ]"
                            label="*顧客(報告区分)"
                            :rules="[(v) => !!v || '顧客(報告区分) は必須です']"
                            persistent-hint
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            apoType = null;
                            creator.apoType = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            newFacility = false;
                            creator.apoType = apoType;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.date)">{{
                      isEmptyValue(creator.date) ? '*アポ獲得日を入力してください' : '*アポ獲得日'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.date(creator.date)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="アポ獲得日">
                          <validation-provider v-slot="{ errors }" name="アポ獲得日" rules="required">
                            <DateTime label="アポ獲得日" v-model="date" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ date | date }} </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            date = null;
                            creator.date = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.date = date;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.demoTarget)">{{
                      isEmptyValue(creator.demoTarget) ? '*デモ対象者を登録してください' : '*デモ対象者'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="creator.demoTarget"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="デモ対象者" rules="required|max:100">
                          <v-combobox
                            v-model="demoTarget"
                            :items="['社長', '支配人', '旦那さん', '女将さん']"
                            label="デモ対象者(手入力可能です)"
                            :rules="[(v) => !!v || 'デモ対象者 は必須です']"
                            counter
                            :maxlength="100"
                            :error-messages="errors"
                            clearable
                            required
                            dense
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            demoTarget = null;
                            creator.demoTarget = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.demoTarget = demoTarget;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.demoItemType)">{{
                      isEmptyValue(creator.demoItemType) ? '*デモ内容を登録してください' : '*デモ内容'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.demo_naiyou(creator.demoItemType)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="デモ内容" rules="required">
                          <v-select
                            v-model="demoItemType"
                            :items="[
                              { text: $options.filters.demo_naiyou(1), value: 1 },
                              { text: $options.filters.demo_naiyou(1 << 1), value: 1 << 1 },
                              { text: $options.filters.demo_naiyou(1 << 2), value: 1 << 2 },
                              { text: $options.filters.demo_naiyou(1 << 3), value: 1 << 3 },
                              { text: $options.filters.demo_naiyou(1 << 4), value: 1 << 4 },
                              { text: $options.filters.demo_naiyou(1 << 6), value: 1 << 6 },
                              { text: $options.filters.demo_naiyou(1 << 7), value: 1 << 7 },
                            ]"
                            label="*デモ内容"
                            :rules="[(v) => !!v || 'デモ内容 は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            demoItemType = null;
                            creator.demoItemType = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.demoItemType = demoItemType;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.demoSchedule)">{{
                      isEmptyValue(creator.demoSchedule) ? '*デモ予定日時を登録してください' : '*デモ予定日時'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.dateTime(creator.demoSchedule)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="デモ予定日時">
                          <validation-provider v-slot="{ errors }" name="デモ予定日時" rules="required">
                            <DateTime label="デモ予定日時" v-model="demoSchedule" :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                  >{{ demoSchedule | dateTime }}
                                </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            demoSchedule = null;
                            creator.demoSchedule = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.demoSchedule = demoSchedule;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.branch) && !isEmptyValue(creator.apointer) && !isEmptyValue(creator.team)">{{
                      isEmptyValue(creator.branch) || isEmptyValue(creator.apointer) || isEmptyValue(creator.team)
                        ? '*アポ担当情報を登録してください'
                        : '*アポ担当'
                    }}</XCheckLabel>
                    <span v-if="creator.branch != null && creator.apointer != null" class="ml-4 success--text">
                      <span v-if="creator.branch != null" class="ml-2">{{ creator.branch.name }}</span>
                      <span v-if="creator.apointer != null" class="ml-2">{{ creator.apointer.name }}</span>
                      <span v-if="creator.team != null" class="ml-2">{{ creator.team.name }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="アポ担当" rules="required">
                              <v-select
                                v-model="branch"
                                :items="dataContext.shitens"
                                label="*支店"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || '支店 は必須です']"
                                :error-messages="errors"
                                return-object
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="アポ担当" rules="">
                              <v-select
                                v-model="apointer"
                                :items="staffs"
                                label="アポ担当"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col md="4">
                            <validation-provider v-slot="{ errors }" name="チーム" rules="">
                              <v-select
                                v-model="team"
                                :items="teams"
                                label="チーム"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            branch = null;
                            apointer = null;
                            team = null;
                            creator.branch = null;
                            creator.apointer = null;
                            creator.team = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.branch = branch;
                            creator.apointer = apointer;
                            creator.team = team;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.confirmDemoScheduleDate)">デモ日程確認日</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.date(creator.confirmDemoScheduleDate)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="デモ日程確認日">
                          <validation-provider v-slot="{ errors }" name="デモ日程確認日" rules="">
                            <DateTime label="デモ日程確認日" v-model="confirmDemoScheduleDate" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                  >{{ confirmDemoScheduleDate | date }}
                                </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                        <p class="text-caption">日付を設定すると「デモ日程確認済」状態となります。</p>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            confirmDemoScheduleDate = null;
                            creator.confirmDemoScheduleDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.confirmDemoScheduleDate = confirmDemoScheduleDate;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.cancelDate)">キャンセル</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.date(creator.cancelDate)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="キャンセル">
                          <validation-provider v-slot="{ errors }" name="キャンセル" rules="">
                            <DateTime label="キャンセル" v-model="cancelDate" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ creator | date }} </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                        <p class="text-caption">日付を設定すると「アポキャンセル」状態となります。</p>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            cancelDate = null;
                            creator.cancelDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.cancelDate = cancelDate;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <span>備考: </span>
                    <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea outlined v-model="memo" label="備考" counter :maxlength="1000" :error-messages="errors" clearable />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>

                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
    <v-card-actions>
      <span class="text-caption">{{ apoCode }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    observer: {
      type: Object,
      required: true,
    },
    apoCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    facilities() {
      return [];
    },
    canCommit() {
      if (this.isEmptyValue(this.creator.date)) return false;
      if (this.isEmptyValue(this.creator.apoType)) return false;
      if (this.isEmptyValue(this.creator.demoTarget)) return false;
      if (this.isEmptyValue(this.creator.demoItemType)) return false;
      if (this.isEmptyValue(this.creator.demoSchedule)) return false;
      if (this.isEmptyValue(this.creator.branch)) return false;
      // アポ担当者・チームはnull許容とする
      return true;
    },

    staffs() {
      let staffs = this.dataContext.staffs;
      if (this.branch != null) {
        staffs = staffs.filter((a) => a.branchCode == this.branch.id);
      }
      // 指定なしを先頭に加える
      staffs.unshift({
        id: null,
        code: null,
        name: '指定なし',
      });

      return staffs;
    },
    teams() {
      let teams = [];
      if (this.branch != null) teams = this.branch.teams;

      // 指定なしを先頭に加える
      teams.unshift({
        id: null,
        code: null,
        name: '指定なし',
      });

      return teams;
    },
  },
  data: () => ({
    //
    initializing: true,
    //
    expansions: null, // multipleではいので単体
    //
    date: new Date(),
    apoType: null,
    //
    facilityName: null,
    facilityTel: null,
    facilityAreaName: null,
    facilityPref: {},
    //
    facility: {
      pref: {},
      areaName: '',
    },

    demoTarget: null,
    demoItemType: null,
    demoSchedule: null,

    branch: null,
    apointer: null,
    team: null,

    confirmDemoScheduleDate: null,
    cancelDate: null,

    memo: null,

    creator: {
      loading: false,

      date: null,
      apoCode: null,

      apoType: null,

      demoTarget: null,
      demoItemType: null,
      demoSchedule: null,

      branch: null,
      apointer: null,
      team: null,

      memo: null,

      confirmDemoScheduleDate: null,
      cancelDate: null,
    },
  }),
  methods: {
    init() {
      //
      this.expansions = null;
      //
      this.date = null;
      this.apoType = null;
      //
      this.facilityName = null;
      this.facilityTel = null;
      this.facilityAreaName = null;
      this.facilityPref = {};
      //
      this.facility = {
        pref: {},
        areaName: '',
      };

      this.demoTarget = null;
      this.demoItemType = null;
      this.demoSchedule = null;

      this.branch = null;
      this.apointer = null;
      this.team = null;

      this.confirmDemoScheduleDate = null;
      this.cancelDate = null;

      this.memo = null;

      this.creator = {
        date: null,
        apoCode: null,

        apoType: null,

        demoTarget: null,
        demoItemType: null,
        demoSchedule: null,

        branch: null,
        apointer: null,
        team: null,

        memo: null,

        confirmDemoScheduleDate: null,
        cancelDate: null,
      };
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();
      //
      this.get('apo', { apoCode: this.apoCode })
        .then((success) => {
          //
          let data = success.data;
          //
          let apointer = { id: null, name: null };
          if (data.apointer != null) {
            apointer.id = data.apointer.code;
            apointer.name = data.apointer.name;
          }

          let branch = { id: null, name: null };
          if (data.branch != null) {
            branch.id = data.branch.code;
            branch.name = data.branch.name;
            branch.teams = data.branch.teams;
          }

          let team = { id: null, name: null };
          if (data.team != null) {
            team.id = data.team.code;
            team.name = data.team.name;
          }

          //
          this.apointer = apointer;
          this.demoTarget = data.demoTarget;

          this.apoType = data.apoType;
          this.demoItemType = data.demoItemType;

          this.demoSchedule = this.$moment(data.demoSchedule).format();

          this.branch = branch;
          this.team = team;
          this.date = data.date;

          this.confirmDemoScheduleDate = data.confirmDemoScheduleDate;
          this.cancelDate = data.cancelDate;
          this.memo = data.memo;

          //
          this.creator.date = data.date;
          this.creator.apoCode = this.apoCode;
          this.creator.apointer = apointer;
          this.creator.demoTarget = data.demoTarget;
          this.creator.apoType = data.apoType;
          this.creator.demoItemType = data.demoItemType;
          this.creator.demoSchedule = this.$moment(data.demoSchedule).format();
          this.creator.branch = branch;
          this.creator.team = team;

          this.creator.confirmDemoScheduleDate = data.confirmDemoScheduleDate;
          this.creator.cancelDate = data.cancelDate;

          this.creator.memo = data.memo;

          // 変更不可
          this.facility = data.facility;
          this.facilityName = data.facility.name;
          this.facilityTel = data.facility.tel;
          this.facilityAreaName = data.facility.areaName;
          this.facilityPref = data.facility.pref;

          this.initializing = false;
        })
        .catch((ex) => {
          console.error('ex', ex);
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        });
      //

      //
      this.initializing = false;
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      //
      let request = this.creator;
      request.date = this.convertDate(this.creator.date);
      request.demoSchedule = this.convertDate(this.creator.demoSchedule);
      request.confirmDemoScheduleDate = this.convertDate(this.creator.confirmDemoScheduleDate);
      request.cancelDate = this.convertDate(this.creator.cancelDate);

      //
      console.log('request', request);

      this.put('apo', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', this.apoCode);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    onCancelClicked() {
      this.$emit('cancel');
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    this.onLoaded();
  },
};
</script>