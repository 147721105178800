<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      デモ日程確認登録
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <ApoResult :apo="apo" />

            <v-divider />

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="creator.confirmStates != 1">
                      <span v-if="creator.confirmStates == 1">*デモ日程を確認</span>
                      <span v-else-if="creator.confirmStates == ConfirmStates.OK">*確認済み {{ creator.demoSchedule | dateTime }}</span>
                      <span v-else-if="creator.confirmStates == ConfirmStates.Change">*日程変更 {{ creator.demoSchedule | dateTime }}</span>
                      <span v-else-if="creator.confirmStates == ConfirmStates.Cancel">*キャンセル</span>
                    </XCheckLabel>
                    <!-- <span class="ml-4">
                  <span v-if="creator.confirmStates == null">*未確認</span>
                  <span v-else-if="creator.confirmStates == ConfirmStates.OK">*確認済み</span>
                  <span v-else-if="creator.confirmStates == ConfirmStates.Change">*日程変更</span>
                  <span v-else-if="creator.confirmStates == ConfirmStates.Cancel">*キャンセル</span>
                </span> -->
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="デモ日程" rules="required">
                          <v-radio-group v-model="confirmStates" :error-messages="errors" dense column>
                            <v-radio label="確認済み" :value="ConfirmStates.OK" />
                            <v-radio label="日程変更" :value="ConfirmStates.Change" />
                            <v-radio label="キャンセル" :value="ConfirmStates.Cancel" />
                          </v-radio-group>
                        </validation-provider>
                      </v-card-text>

                      <template v-if="confirmStates == ConfirmStates.OK">
                        <p>デモ時間の変更がある場合は以下を修正してください</p>
                        <v-time-picker v-model="demoScheduleTime"></v-time-picker>
                      </template>

                      <template v-if="confirmStates == ConfirmStates.Change">
                        <p>変更後の日程を登録してください。</p>
                        <XFieldSet label="デモ予定日時">
                          <validation-provider v-slot="{ errors }" name="デモ予定日時" rules="">
                            <DateTime label="デモ予定日時" v-model="demoSchedule" :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                  >{{ demoSchedule | dateTime }}
                                </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </template>

                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            confirmStates = null;
                            creator.confirmStates = false;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid || confirmStates == 1"
                          @click="
                            creator.confirmStates = confirmStates;
                            //
                            if (confirmStates == ConfirmStates.OK) {
                              creator.demoSchedule = $moment(`${demoScheduleDate} ${demoScheduleTime}`).toDate();
                              apo.demoSchedule = $moment(`${demoScheduleDate} ${demoScheduleTime}`).toDate();
                            } else if (confirmStates == ConfirmStates.Change) {
                              creator.demoSchedule = demoSchedule;
                              apo.demoSchedule = demoSchedule;
                            }

                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <XCheckLabel :value="!isEmptyValue(creator.memo)">{{
                    isEmptyValue(creator.memo) ? '備考を登録してください' : '備考'
                  }}</XCheckLabel>
                  <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea v-model="memo" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>

    <v-card-actions>
      <span class="text-caption">{{ apoCode }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import ApoResult from './Result';

const ConfirmStates = {
  OK: 1 << 1, // デモ日程確認OK
  Change: 1 << 2, // 日程変更
  Cancel: 1 << 5, // キャンセル
};

export default {
  components: {
    ApoResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    observer: {
      type: Object,
      required: true,
    },

    apoCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () =>
          !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit && this.creator.confirmStates != 1,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    ConfirmStates() {
      return ConfirmStates;
    },
    facilities() {
      return [];
    },
    canCommit() {
      if (!this.creator.confirmStates) return false;
      return true;
    },
    canCancel() {
      if (this.isEmptyValue(this.creator.memo)) return false;
      return true;
    },
  },
  data: () => ({
    initializing: true,
    //
    expansions: null, // multipleではいので単体
    //
    apo: null,

    //
    confirmStates: null,
    demoSchedule: null,
    demoScheduleDate: null,
    demoScheduleTime: null,
    memo: null,

    creator: {
      loading: false,

      confirmStates: null,
      demoSchedule: null,
      demoScheduleDate: null,
      demoScheduleTime: null,
      memo: null,
    },
  }),
  methods: {
    init() {
      //
      this.expansions = null;
      //
      this.confirmStates = null;

      this.demoSchedule = null;
      this.demoScheduleDate = null;
      this.demoScheduleTime = null;
      this.memo = null;

      this.creator.confirmStates = null;

      this.demoSchedule = null;
      this.demoScheduleDate = null;
      this.demoScheduleTime = null;
      this.creator.memo = null;
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();
      //
      this.get('apo', { apoCode: this.apoCode })
        .then((success) => {
          //
          let data = success.data;
          //
          this.apo = data;

          let demoSchedule = this.$moment(data.demoSchedule).format();
          let demoScheduleDate = this.$moment(data.demoSchedule).format('YYYY-MM-DD');
          let demoScheduleTime = this.$moment(data.demoSchedule).format('HH:mm');

          this.confirmStates = data.state;

          this.demoSchedule = demoSchedule;
          this.demoScheduleDate = demoScheduleDate;
          this.demoScheduleTime = demoScheduleTime;

          //
          this.memo = data.memo;
          this.creator.confirmStates = data.state;
          this.creator.demoSchedule = demoSchedule;
          this.creator.demoScheduleDate = demoScheduleDate;
          this.creator.demoScheduleTime = demoScheduleTime;
          this.creator.memo = data.memo;

          this.initializing = false;
        })
        .catch(() => {
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        }); //
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      switch (this.creator.confirmStates) {
        //
        case ConfirmStates.OK:
          {
            if (!confirm('登録してよろしいですか？')) return;
            //
            this.put('apo/demoSchedule/confirm', {
              apoCode: this.apoCode,
              confirmed: true,
              demoSchedule: this.convertDate(this.creator.demoSchedule),
              memo: this.creator.memo,
            })
              .then((success) => {
                //
                this.showSuccessSnackbar(success);
                //
                this.$emit('commit', this.apoCode);
              })
              .catch((error) => this.showError(error));
          }

          break;

        //
        case ConfirmStates.Change:
          {
            if (!confirm('日程変更を登録してよろしいですか？')) return;
            //
            //
            this.put('apo/demoSchedule', {
              apoCode: this.apoCode,
              demoSchedule: this.convertDate(this.creator.demoSchedule),
              memo: this.creator.memo,
            })
              .then((success) => {
                //
                this.showSuccessSnackbar(success);
                //
                this.$emit('commit', this.apoCode);
              })
              .catch((error) => this.showError(error));
          }
          break;

        //
        case ConfirmStates.Cancel:
          {
            if (!confirm('キャンセルを登録してよろしいですか？')) return;
            //
            this.put('apo/cancel', { apoCode: this.apoCode, memo: this.creator.memo })
              .then(() => {
                //
                this.showSuccess('キャンセル処理が完了しました');
                //
                this.$emit('commit', this.apoCode);
              })
              .catch((error) => this.showError(error));
          }
          break;
      }
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {},
};
</script>