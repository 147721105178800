<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      導入取材報告
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>

    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="demo">
              <DemoResult :demo="demo" />
              <v-divider />
            </template>

            <template v-if="hpCoverage">
              <HPCoverageResult :hpCoverage="hpCoverage" />
              <v-divider />
            </template>

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.date)">{{
                      isEmptyValue(creator.date) ? '*導入取材完了日を入力してください' : '*導入取材完了日'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.date(creator.date)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="導入取材完了日">
                          <validation-provider v-slot="{ errors }" name="導入取材完了日" rules="required">
                            <DateTime label="導入取材完了日" v-model="date" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ date | date }} </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            date = null;
                            creator.date = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.date = date;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="creator.setuper.id != null && creator.coverager.id != null && creator.submiter.id != null">{{
                      isEmptyValue(creator.setuper) || isEmptyValue(creator.submiter)
                        ? '導入・取材・構成担当・構成提出予定日情報を登録してください'
                        : '導入・取材・構成担当・構成提出予定日'
                    }}</XCheckLabel>
                    <span
                      v-if="creator.setuper.id != null && creator.coverager.id != null && creator.submiter.id != null"
                      class="ml-4 success--text"
                    >
                      導入:<span v-if="creator.setuper != null" class="ml-2">{{ creator.setuper.name }}</span> 取材:<span
                        v-if="creator.coverager != null"
                        class="ml-2"
                        >{{ creator.coverager.name }}</span
                      >
                      構成:<span v-if="creator.submiter != null" class="ml-2">{{ creator.submiter.name }}</span>
                    </span>

                    <span v-if="creator.submitscheduleDate != null" class="ml-4 success--text"
                      >構成提出予定日:{{ creator.submitscheduleDate | date }}</span
                    >
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <p class="text-caption">この「拠点」はデモ結果から参照しています。変更しても結果に反映されません。</p>
                            <validation-provider v-slot="{ errors }" name="支店" rules="">
                              <v-select
                                v-model="branch"
                                :items="dataContext.shitens"
                                label="支店"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="導入担当" rules="">
                              <v-select
                                v-model="setuper"
                                :items="staffs"
                                label="導入担当"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>

                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="取材担当" rules="">
                              <v-select
                                v-model="coverager"
                                :items="staffs"
                                label="取材担当"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>

                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="構成担当" rules="">
                              <v-select
                                v-model="submiter"
                                :items="staffs"
                                label="構成担当"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <XFieldSet label="構成提出予定日">
                          <validation-provider v-slot="{ errors }" name="構成提出予定日" rules="">
                            <DateTime label="構成提出予定日" v-model="submitscheduleDate" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                  >{{ submitscheduleDate | date }}
                                </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            setuper = null;
                            coverager = null;
                            submiter = null;
                            submitscheduleDate = null;
                            creator.setuper = null;
                            creator.coverager = null;
                            creator.submiter = null;
                            creator.submitscheduleDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.setuper = setuper;
                            creator.coverager = coverager;
                            creator.submiter = submiter;
                            creator.submitscheduleDate = submitscheduleDate;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.meta.coverageCollectType)">{{
                      isEmptyValue(creator.meta.coverageCollectType) ? '*リース用紙回収区分を登録してください' : '*リース用紙回収区分'
                    }}</XCheckLabel>
                    <span
                      class="ml-4 success--text"
                      v-text="$options.filters.coverageCollectTypes(creator.meta.coverageCollectType)"
                    ></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="リース用紙回収区分" rules="required">
                          <v-select
                            v-model="coverageCollectType"
                            :items="[
                              { text: $options.filters.coverageCollectTypes(1), value: 1 },
                              { text: $options.filters.coverageCollectTypes(1 << 1), value: 1 << 1 },
                              { text: $options.filters.coverageCollectTypes(1 << 2), value: 1 << 2 },
                              { text: $options.filters.coverageCollectTypes(1 << 9), value: 1 << 9 },
                            ]"
                            label="*リース用紙回収区分"
                            :rules="[(v) => !!v || 'リース用紙回収区分は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            coverageCollectType = null;
                            creator.meta.coverageCollectType = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid || coverageCollectType == 0"
                          @click="
                            creator.meta.coverageCollectType = coverageCollectType;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                    <p class="text-caption">※未回収・回収OK(提出待ち)の場合は理由を備考に記入してください。</p>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.moveTime) && !isEmptyValue(creator.coverageTime)">{{
                      isEmptyValue(creator.moveTime) || isEmptyValue(creator.coverageTime)
                        ? '移動・取材時間を登録してください'
                        : '移動・取材時間'
                    }}</XCheckLabel>
                    <span v-if="creator.moveTime != null" class="ml-4 success--text">
                      移動:<span class="ml-2">{{ creator.moveTime }}時間</span> 取材:<span class="ml-2"
                        >{{ creator.coverageTime }}時間</span
                      >
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="移動時間" rules="max:100">
                          <v-text-field
                            type="number"
                            v-model.number="moveTime"
                            label="移動時間"
                            clearable
                            dense
                            suffix="時間"
                            counter
                            :maxlength="2"
                            :error-messages="errors"
                          />
                        </validation-provider>

                        <validation-provider v-slot="{ errors }" name="取材時間" rules="max:100">
                          <v-text-field
                            type="number"
                            v-model.number="coverageTime"
                            label="取材時間"
                            clearable
                            dense
                            suffix="時間"
                            counter
                            :maxlength="2"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            moveTime = null;
                            coverageTime = null;
                            creator.moveTime = null;
                            creator.coverageTime = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.moveTime = moveTime;
                            creator.coverageTime = coverageTime;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.cost)">{{
                      isEmptyValue(creator.cost) ? '取材経費を登録してください' : '取材経費'
                    }}</XCheckLabel>
                    <span v-if="creator.cost != null" class="ml-4 success--text">
                      <span class="ml-2">{{ creator.cost | priceJP }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="取材経費" rules="max:100">
                          <v-text-field
                            type="number"
                            v-model.number="cost"
                            label="取材経費"
                            clearable
                            dense
                            suffix="円"
                            counter
                            :maxlength="100"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            cost = null;
                            creator.cost = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.cost = cost;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    その他
                    <span class="success--text">
                      <span class="ml-4">{{ creator.meta.addCoverage ? '追加取材' : '' }}</span>
                      <span class="ml-4">{{ creator.meta.domain | domain }}</span>
                      <span v-if="creator.meta.domainName != null" class="ml-4"
                        >[{{ creator.meta.domainName }}{{ creator.meta.domainSuffix }}]</span
                      >
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <div class="d-flex justify-space-start">
                          <v-checkbox v-model="meta.addCoverage" label="追加取材" />
                        </div>

                        <XFieldSet label="ドメイン">
                          <v-row>
                            <v-col cols="12" md="5">
                              <validation-provider v-slot="{ errors }" name="ドメイン" rules="">
                                <v-radio-group v-model.number="meta.domain" :error-messages="errors" column dense>
                                  <v-radio
                                    v-for="(item, index) in [
                                      { value: 1, text: '既存ドメイン使用' },
                                      { value: 1 << 1, text: 'ドメイン移管' },
                                      { value: 1 << 2, text: '取得代行' },
                                    ]"
                                    :key="index"
                                    :label="item.text"
                                    :value="item.value"
                                  ></v-radio>
                                </v-radio-group>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" md="4">
                              <validation-provider v-slot="{ errors }" name="ドメイン名" rules="">
                                <v-text-field
                                  v-model="meta.domainName"
                                  label="ドメイン名"
                                  counter
                                  :maxlength="100"
                                  :error-messages="errors"
                                  clearable
                                  dense
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" md="3">
                              <validation-provider v-slot="{ errors }" name="domain" rules="">
                                <v-combobox
                                  v-model="meta.domainSuffix"
                                  :items="['.com', '.net', '.jp']"
                                  label="domain"
                                  counter
                                  :maxlength="100"
                                  :error-messages="errors"
                                  outlined
                                  dense
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            meta.addCoverage = false;
                            meta.domain = null;
                            meta.domainName = null;
                            meta.domainSuffix = null;

                            creator.meta.addCoverage = false;
                            creator.meta.domain = null;
                            creator.meta.domainName = null;
                            creator.meta.domainSuffix = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.meta.addCoverage = meta.addCoverage;
                            creator.meta.domain = meta.domain;
                            creator.meta.domainName = meta.domainName;
                            creator.meta.domainSuffix = meta.domainSuffix;

                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <XCheckLabel :value="!isEmptyValue(creator.memo)">{{
                    isEmptyValue(creator.memo) ? '備考を登録してください' : '備考'
                  }}</XCheckLabel>
                  <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea
                            v-model="memo"
                            label="備考"
                            rows="3"
                            counter
                            :maxlength="1000"
                            :error-messages="errors"
                            clearable
                            outlined
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
    <v-card-actions>
      <span class="text-caption">{{ hpCoverageCode }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import DemoResult from '@/components/demos/Result';
import HPCoverageResult from '@/components/hpCoverages/Result';

export default {
  components: {
    DemoResult,
    HPCoverageResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },
    hpCoverageCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    staffs() {
      let staffs = this.dataContext.staffs;
      if (this.branch != null) {
        staffs = staffs.filter((a) => a.branchCode == this.branch.id);
      }
      return staffs;
    },

    teams() {
      if (this.branch != null) return this.branch.teams;
      return [];
    },

    canCommit() {
      if (this.isEmptyValue(this.creator.date)) return false;
      // if (this.isEmptyValue(this.creator.setuper)) return false;
      // if (this.isEmptyValue(this.creator.coverager)) return false;
      // if (this.isEmptyValue(this.creator.submiter)) return false;
      if (this.isEmptyValue(this.creator.meta.coverageCollectType)) return false;
      // if (this.isEmptyValue(this.creator.submitscheduleDate)) return false;
      return true;
    },
  },
  data: () => ({
    //
    initializing: false,
    facilities: [],
    demo: null,
    hpCoverage: null,
    branch: null,
    //
    expansions: null, // multipleではいので単体

    date: null,
    setuper: null,
    coverager: null,
    submiter: null,
    moveTime: null,
    coverageTime: null,
    cost: null,
    submitscheduleDate: null,

    coverageCollectType: null, // meta の内容だがインスタンスを参照されるため別途しておく

    meta: {
      addCoverage: false,
      domain: 1,
      domainName: null,
      domainSuffix: null,
    },

    memo: null,

    creator: {
      loading: false,

      date: null,
      setuper: null,
      coverager: null,
      submiter: null,
      moveTime: null,
      coverageTime: null,
      cost: null,
      submitscheduleDate: null,

      meta: {
        addCoverage: false,
        domain: 1,
        domainName: null,
        domainSuffix: null,
        coverageCollectType: null,
      },

      memo: null,
    },
  }),
  methods: {
    init() {
      //
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();

      this.get('hpCoverage', { hpCoverageCode: this.hpCoverageCode })
        .then((success) => {
          //
          let data = success.data;

          let setuper = { id: null, name: null };
          if (data.setuper != null) {
            setuper.id = data.setuper.code;
            setuper.name = data.setuper.name;
          }
          let coverager = { id: null, name: null };
          if (data.coverager != null) {
            coverager.id = data.coverager.code;
            coverager.name = data.coverager.name;
          }
          let submiter = { id: null, name: null };
          if (data.submiter != null) {
            submiter.id = data.submiter.code;
            submiter.name = data.submiter.name;
          }

          // 「入力側」(creatorじゃないほう)取材予定日を導入取材完了日の初期値に設定する
          let date = data.date;
          let scheduleDate = data.scheduleDate;
          if (date == null) date = scheduleDate;

          //
          let moveTime = data.moveTime;
          let coverageTime = data.coverageTime;

          this.demo = data.demo;
          this.date = date;
          this.setuper = setuper;
          this.coverager = coverager;
          this.submiter = submiter;
          this.moveTime = moveTime;
          this.coverageTime = coverageTime;
          this.cost = data.cost;
          this.submitscheduleDate = data.submitscheduleDate;

          this.coverageCollectType = data.meta.coverageCollectType; // metaの内容だがインスタンスを参照されるため別途出しておく

          this.meta = data.meta;
          this.memo = data.memo;
          //

          this.creator.demo = data.demo;
          this.creator.date = data.date;
          this.creator.setuper = setuper;
          this.creator.coverager = coverager;
          this.creator.submiter = submiter;
          this.creator.moveTime = moveTime;
          this.creator.coverageTime = coverageTime;
          this.creator.cost = data.cost;
          this.creator.submitscheduleDate = data.submitscheduleDate;
          this.creator.meta = data.meta;
          this.creator.memo = data.memo;
          //
          this.branch = {
            id: data.demo.branch.code,
            name: data.demo.branch.name,
          };
          //
          this.initializing = false;
        })
        .catch((ex) => {
          console.error('ex', ex);
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        });
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //
      let request = this.creator;
      request.hpCoverageCode = this.hpCoverageCode;
      request.date = this.convertDate(this.creator.date);
      request.submitscheduleDate = this.convertDate(this.creator.submitscheduleDate);

      // nullable
      request.moveTime = this.convertNumber(request.moveTime);
      request.coverageTime = this.convertNumber(request.coverageTime);
      request.cost = this.convertNumber(request.cost);
      //
      this.put('hpCoverage', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', this.hpCoverageCode);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    // this.onLoaded();
  },
};
</script>