<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      事前審査提出
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>
    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="apo">
              <ApoResult :apo="apo" />
              <v-divider />
            </template>

            <template v-if="demo">
              <DemoResult :demo="demo" />
              <v-divider />
            </template>

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <span><XCheckLabel :value="creator.isSubmitPreExaminationDate">事前審査提出: </XCheckLabel></span>
                    <span class="ml-2">{{ creator.isSubmitPreExaminationDate ? '提出' : '未提出' }}</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <v-checkbox v-model="isSubmitPreExaminationDate" label="提出" />
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            isSubmitPreExaminationDate = false;
                            creator.isSubmitPreExaminationDate = false;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.isSubmitPreExaminationDate = isSubmitPreExaminationDate;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <span>備考: </span>
                    <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea
                            v-model="memo"
                            label="備考"
                            rows="3"
                            counter
                            :maxlength="1000"
                            :error-messages="errors"
                            clearable
                            outlined
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                        >
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>

    <v-card-actions>
      <span class="text-caption">{{ demoCode }}</span>
    </v-card-actions>
  </v-card>
</template>

<script>
import ApoResult from '@/components/apos/Result';
import DemoResult from './Result';
export default {
  components: {
    ApoResult,
    DemoResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    observer: {
      type: Object,
      required: true,
    },

    demoCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    isOK() {
      return this.creator.resultType == 1 << 1;
    },
    canCommit() {
      return true;
    },
  },
  data: () => ({
    //
    initializing: true,

    facilities: [],
    apo: null,
    demo: null,
    //
    expansions: null, // multipleではいので単体

    isSubmitPreExaminationDate: false,
    memo: null,

    creator: {
      loading: false,

      isSubmitPreExaminationDate: false,
      memo: null,
    },
  }),
  methods: {
    init() {
      this.isSubmitPreExaminationDate = false;
      this.memo = false;

      this.creator.isSubmitPreExaminationDate = false;
      this.creator.memo = false;
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();

      //

      //
      this.get('demo', { demoCode: this.demoCode })
        .then((success) => {
          //
          let data = success.data;

          this.apo = data.apo;
          this.demo = data;
          //

          this.isSubmitPreExaminationDate = data.isSubmitPreExaminationDate;
          this.memo = data.memo;
          this.creator.isSubmitPreExaminationDate = data.isSubmitPreExaminationDate;
          this.creator.memo = data.memo;

          this.initializing = false;
        })
        .catch(() => {
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        });
      //
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      let request = {
        demoCode: this.demoCode,
        isSubmit: this.creator.isSubmitPreExaminationDate,
        memo: this.creator.memo,
      };

      //
      this.put('demo/SubmitPreExamination', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', this.demoCode);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    // this.onLoaded();
  },
};
</script>